import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../../../App.css";
// import center from '../../../assets/css/custom.css';
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { AddressMap } from "../components/AddressMap";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import { ContactSlider } from "../components/ContactSlider";
import ContactCard from "../components/ContactCard";
import BlankSpace from "../components/BlankSpace";
import ImgListCard from "../components/ImgListCard";
import PhoneCard from "../components/PhoneCard";
import DirectionCard from "../components/DirectionCard";
import BusinessCard from "../components/BusinessCard";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";

export const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us: 6040 US highway 93, Eureka, MT, United States, Montana";
    // ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="Contact">
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us: 6040 US highway 93, Eureka, MT, United States, Montana</title>
          <link rel="canonical" href="/contact" />
          <meta
            name="description"
            content="We are a full service bar & restaurant in Eureka, MT - <span>Where the west is still wild!"
          />
        </Helmet>
      </HelmetProvider>
      <ContactSlider />
      <BlankSpace />
      <Container>
        <Row>
          {<col className="whitespace"></col>}
          <Col xs={6}>
            <ImgListCard />
          </Col>
          <Col xs={6} className="center">
            <ContactCard />
          </Col>
        </Row>
      </Container>
      <Container fluid="md">
        <Row>
          <Col xs={12} sm={4} className="whitespace left">
            <PhoneCard />
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
            <DirectionCard />
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
            <BusinessCard />
          </Col>
        </Row>
      </Container>
      <BlankSpace />
      <AddressMap />
      <Footer />
    </div>
  );
};
