import React, { useEffect, useState, Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import { AdminRoutes } from "./features/admin";
import {
  ProductDetails,
  Checkout,
  Home,
  OrderHistory,
  MainNavigation,
  DynamicContent,
  StatusScreen,
  RestaurantLayout,
  RestaurantSearch,
  Favorites,
  Main,
  TimeComponent,
  Restaurant,
  RestaurantHome,
  RestaurantMenu,
  SelectAddress,
  Worldpay,
} from "./features/eComm";

import { DeliveryMap } from "./features/eComm/DeliveryMapV2";

import { useMediaQuery } from "@material-ui/core";

import {
  About,
  OrderNow,
  Events,
  Jobs,
  Contact,
  PhotoGallery,
  PrivacyPolicy,
  Mobile,
  FlatBreadPizza,
  TermsandCondition,
  Login,
  LunchMenu,
  DinnerMenu,  
  Register,
  EloyaltyForgotPW,
  EloyaltyResetPW,
  Rewards,
} from "./features/eComm/pages";
import { Loader } from "./components";
import { HomeDriver, NavDriver, Drive } from "./features/driver";
import { toast, ToastContainer } from "react-toastify";
import firebase, { auth, db } from "./firebase/config";
import { setLoading, setUser } from "./redux/publicReducer/actions";
import { useDispatch, useSelector } from "react-redux";

import { AdminLogin } from "./features/public/AdminLogin";
import { HomeScreen } from "./features/public/HomeScreen";
import { HomepageLanding } from "./features/public/HomepageLanding";
import { Signin } from "./features/public/Signin";
import { ClientRegister } from "./features/public/ClientRegistration";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAlan } from "./hooks/useAlan";
import { NewLayout, NewCategory } from "./Sandbox";
import { CartPage } from "./features/eComm/cart/CartPage";
import { PaymentHandler } from "./features/paymentHandler/";
import { RewardsAsPayment } from "./features/eComm/rewards";
import { useHistory } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./App.scss";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { removeExpiredItems, setCart } from "./redux/cartReducer/actions";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const enableMenu = process.env.REACT_APP_ENABLE_MENU;

function App() {
  useEffect(() => {
    // document.title = "Gluten-Free Restaurant"
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const adminRef = db.collection("admins");
  const clientRef = db.collection("users");
  const { user, loading } = useSelector((state) => state.public);
  const [deviceToken, setDeviceToken] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/sync-to-menu-info/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
      })
      .catch((error) => {
        return;
      });

    const paymentId = localStorage.getItem("pid");

    if (paymentId) {
      const paymentStatusUrl =
        "http://ecommv2.servingintel.com/postpayment/verifypayment/" +
        siteId +
        "/" +
        paymentId;
      axios
        .get(paymentStatusUrl)
        .then((response) => {
          const data = response.data;

          // If payment is approved delete the cart items
          if (data.Status === "Approved") {
            dispatch(setCart([]));
            localStorage.removeItem("pid");
          }
        })
        .catch((error) => {
          return;
        });
    }
  }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  // const userOrderRef = db
  //   .collection("orders")
  //   .where("status", "in", ["pending", "IN_PROGRESS"]);

  //console.log(user);
  //was enabled
  // useEffect(() => {
  //   let unsubscribe;
  //   if (firebase.messaging.isSupported()) {
  //     unsubscribe = firebase
  //       .messaging()
  //       .getToken({
  //         vapidKey:
  //           "BNTWlJoOh_VRNYaB5PD7Hw0y0ZaqNc75sRj84ltZvx1BMmc7E2dz7HSIdkNvuPNFt4GWMP9OC-UYEY2lonU-2vQ",
  //       })
  //       .then((currentToken) => {
  //         if (currentToken) {
  //           console.log(currentToken);
  //           localStorage.setItem("deviceToken", currentToken);
  //           setDeviceToken(currentToken);
  //         } else {
  //           // Show permission request UI
  //           console.log();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("An error occurred while retrieving token. ", err);
  //       });
  //   }
  //   return unsubscribe;
  // }, []);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (location) => {
  //         console.log(location, "location");
  //       },
  //       (err) => {
  //         console.log(err);
  //       },
  //       { timeout: 10000, enableHighAccuracy: true }
  //     );
  //     console.log(true);
  //   } else {
  //     console.log(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (firebase.messaging.isSupported()) {
  //     firebase.messaging().onMessage((payload) => {
  //       console.log(payload, "payload");
  //     });
  //   }
  // }, []);

  //was enabled
  // useEffect(() => {
  //   auth.onAuthStateChanged(async (user) => {
  //     console.log(user, "user");
  //     dispatch(setLoading(true));
  //     if (user) {
  //       adminRef.doc(user.uid).onSnapshot((doc) => {
  //         console.log(doc.data());
  //         if (doc.data()) {
  //           return dispatch(setUser({ ...doc.data(), id: doc.id }));
  //         }
  //         dispatch(setLoading(false));
  //       });
  //       clientRef.doc(user.uid).onSnapshot((doc) => {
  //         if (doc.data()) {
  //           return dispatch(setUser({ ...doc.data(), id: doc.id }));
  //         }
  //         dispatch(setLoading(false));
  //       });
  //     } else {
  //       dispatch(setUser(true));

  //       dispatch(setLoading(false));
  //     }
  //     dispatch(setLoading(false));
  //   });
  // }, []);

  // useEffect(() => {
  //   if (deviceToken) {
  //     if (firebase.messaging.isSupported()) {
  //       db.collection("notifications").add({
  //         name: "test",
  //         token: deviceToken,
  //       });
  //     }
  //   }
  // }, [deviceToken]);

  // useEffect(() => {
  //   if (user && user?.userType === "CUSTOMER") {
  //     userOrderRef.where("user", "==", user.id).onSnapshot((snapshot) => {
  //       snapshot.docs.forEach((doc) => {
  //         console.log(doc.data(), "CUSTOMER");
  //       });
  //     });
  //   }
  // }, [user]);
  // useAlan();

  return (
    <>
      <Loader open={loading} />
      <Switch>
        <Route
          exact
          path="/"
          component={!isMobile ? HomepageLanding : Mobile}
        />
        <Route exact path="/sandbox" component={NewLayout} />
        <Route exact path="/sand-cat" component={NewCategory} />
        <Route exact path="/sand-time" component={TimeComponent} />
        {user?.userType === "ADMIN" && <AdminRoutes />}
        {user?.userType === "DRIVER" && (
          <>
            <NavDriver />
            <Route exact path={"/home"} component={HomeDriver} />
            <Route exact path="/drive/:orderId" component={Drive} />
          </>
        )}
        <Route exact path="/search/" component={RestaurantSearch} />
        <Route exact path="/payment-handler" component={PaymentHandler} />
        <Route exact path="/cart/" component={CartPage} />
        <Route exact path="/servingintel-admin-login" component={AdminLogin} />
        <Route exact path="/sign-in" component={Signin} />
        <Route exact path="/registration" component={ClientRegister} />
        <Route exact path="/home" component={HomepageLanding} />
        <Route exact path="/about" component={About} />
        <Route exact path="/ordernow" component={OrderNow} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/jobs" component={Jobs} />
        <Route exact path="/photo-gallery" component={PhotoGallery} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/Events" component={Events} />
        <Route exact path="/lunch-menu" component={LunchMenu} />
        <Route exact path="/dinner-menu" component={DinnerMenu} />
        <Route exact path="/flat-bread-pizza" component={FlatBreadPizza} />
        <Route exact path="/mobile" component={Mobile} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsandCondition}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/activate/:activationCode" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={EloyaltyForgotPW} />
        <Route exact path="/reset-password" component={EloyaltyResetPW} />
        <Route exact path="/rewards" component={Rewards} />
        <Route
          exact
          path="/order-history"
          component={() => {
            return (
              <>
                <MainNavigation />
                <OrderHistory />
              </>
            );
          }}
        />
        <Route exact path="/select-address" component={SelectAddress} />
        <Route exact path="/payment-wpay/" component={Worldpay} />
        <Route
          exact
          path="/payment-wpay/rewards"
          component={RewardsAsPayment}
        />
        /*
        <Route exact path="/home/" component={RestaurantLayout} />
        */
        <Route exact path="/main/" component={Main} />
        {enableMenu == 1 && (
          <Route exact path={"/menu"} component={RestaurantHome} />
        )}
        {enableMenu == 1 && (
          <Route
            exact
            path={["/menu/:category", "/menu/:category/:productName"]}
            component={RestaurantMenu}
          />
        )}
        {enableMenu !== 1 && (
          <Route
            exact
            path={["/menu/", "/menu/:productName"]}
            component={Restaurant}
          />
        )}
        <Route exact path="/favorites/" component={Favorites} />
        <Route path="/menu/:productName" component={ProductDetails} />
        <Route path="/checkout" component={Checkout} />
        <Route exact path="/map" component={DeliveryMap} />
        <Route path="/dynamic/:page/:contentId" component={DynamicContent} />
        <Route path="/order-status/:orderId" exact component={StatusScreen} />
      </Switch>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default withRouter(App);
