import React from 'react'
import { useState, useEffect} from 'react'
import { Link, animateScroll} from 'react-scroll'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import "../../../assets/css/custom.css"


function StickyCategoriesNav({categories}) {
  // const [value, setValue] = React.useState(0);
  // console.log(categories)
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <div className="stickyCategoriesNav">
        <Box>
             <Tabs variant="scrollable">
                { categories.map(item => (
                 <li key={item.DepartmentID}>
                  <Link to={item.DepartmentID} smooth={true} offset={-220} duration={200} spy={true}>{item.DepartmentDescription}</Link>
                 </li>
                 ))}
            </Tabs>
        </Box>
    </div>
  )

}




export default StickyCategoriesNav