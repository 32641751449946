import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const Jobslider = () => {
  return (
      
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <Col>
              <h1 className="center gold whitespace">
                Cook Wanted
              </h1>
                <h5 className="center white">
                {" "}
                Please send resume to: <a href="mailto:trapperssaloon@gmail.com">trapperssaloon@gmail.com</a> or come by and fill out an application.
              </h5>
              <h2 className="padding-top-20 center gold">
                You may call <a aria-label="Call Phone Number +1 406-889-3400" href="tel:+1 406-889-3400" className="red-link">+1 406-889-3400</a>
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
</div>
  );
};
