import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Row, Col, h6, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";

export const EventsSlider = () => {
  return (
      
<div className="EventsSlider">
      <div className="EventsHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7} >
              <h1 className="left gold">
                Enjoy our amazing menu!!!
              </h1>
              <Typography variant="body1" className="white">
                {" "}
                Come out Trappers Saloon and get a FREE domestic beer with your lunch!! We have football going on all our TVs !!! Have a great day everyone!!
                </Typography>    
                <h2 className="left gold whitespace">
                Health & Safety
              </h2>
              <Typography variant="body1" className="white">
                {" "}
                Staff wear masks | Staff get temperature checks | Staff required to disinfect surfaces between visits
                </Typography>                            
            </Col>
            {/* <Col xs={12} sm={4} md={5} className="rightImg">
            </Col> */}
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
  );
};