/*import "../../../../../App.css";*/
import React from "react";
import "../../../../../assets/css/custom.css";
import {
  Container,
  Col,
} from "react-bootstrap";
import { FaInstagramSquare, FaFacebookF, FaTwitterSquare } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import Typography from '@mui/material/Typography';

export const SocialConnect = () => {
  return (
    <div className="social-connect">
      <Container className="social-icons ">
        <Col xs={12} className="social-title">
        <Typography variant="h4" gutterBottom component="div">
          Social Connect
        </Typography>
        </Col>
        <Col xs={12} className="social-icons icon">
          <ul>
            <li>
              <a href="https://www.facebook.com/trapperssaloon" target="_blank">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/trapperssaloon" target="_blank">
                <FaTwitterSquare />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/trapperssaloon" target="_blank">
                <FaInstagramSquare />
              </a>
            </li>
          </ul>
        </Col>
      </Container>
    </div>
  );
};