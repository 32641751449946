import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/showcase-icon.png";
import "../../../../../App.css";
// import whitespace from "../../../../../assets/css/custom.css";
import { Container, Row, Col, Button, h1, h2, p, h3, hr } from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img width="100%" height="100%" title="Trappers Saloon Showcase Image Left" className="img-sm" src={showcaseimg} alt="Trappers Saloon Showcase Image Left" loading="lazy" />
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1>
              We are a full service bar and restaurant in Eureka Montana
            </h1>
            <h2 className="margin-top-20">Get to know us</h2>
          </div>
          <div className="left margin-top-20">
            <p>
              {" "}
              In order to provide you with the best service, reservations are strongly recommended for groups of 8 or more.
            </p>
          </div>
          <div className="showcase-right-text left">
            <Col xs={6} md={3} className="img-icon">
              <img width="100%" height="100%" title="Trappers Saloon Showcase Icon" src={showcaseimg1} size="25" alt="Trappers Saloon Showcase Icon" loading="lazy" />
            </Col>
            <p>
              <h3> Discover Flat-Bread Pizza Menu </h3>
              <span>Check out our selection of amazing products available for pickup!</span>
              <br></br>
              <span><Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/flat-bread-pizza"
              >
                Order Now{" "}
              </Button>
              </span>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};