import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../../../App.css";
// import center from '../../../assets/css/custom.css';
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import FlatBreadPizzas from "../components/FlatBreadPizzas";
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import { Helmet, HelmetProvider } from "react-helmet-async";

export const FlatBreadPizza = () => {
  return (

    <div className="FlatBreadPizza">
      <MenuNavigation/>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Flat-Bread Pizza: We are a full service bar and restaurant in Eureka Montana</title>
          <link rel="canonical" href="/contact" />
          <meta
            name="description"
            content="All Pizzas Served with Either a Flatbread or Cauliflower Rice Crust ($1 Extra)"
          />
        </Helmet>
      </HelmetProvider>
      <div className="HeaderImg">
            <Container>
            <Row>
              <Col xs={12} className="showcase">
              <h2 className="title center gold">Flat-Bread Pizza</h2>
              </Col>
              <Col xs={10} className="center" >
              <h6 className="white">All Pizzas Served with Either a Flatbread or Cauliflower Rice Crust ($1 Extra)</h6>
              </Col>
            </Row>
          </Container> 
      </div>
      <Container>
      <FlatBreadPizzas />
      </Container>
      <Footer/>
    </div>
  );
};
