import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RewardsPoints from "./RewardsPoints";
import Paper from "@mui/material/Paper";
import { withStyles } from "@material-ui/core/styles";
import RewardsPurchases from "./RewardsPurchases";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConstructionOutlined } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const styles = {
    root: {
      padding: "0",
    },
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: "0", background: "beige" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const serverId = process.env.REACT_APP_SERVER_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteId = process.env.REACT_APP_SITE_ID;

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [purchases, setPurchases] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/eloyalty-rewards/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      getCookie("sic_email");
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        setAvailableRewards(response.data.Total);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/eloyalty-purchases/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      getCookie("sic_email");
    axios
      .get(url)
      .then((response) => {
        let temp = [];
        response.data.SalesByEmails.map((purchase) => {
          const url =
            "https://ecommv2.servingintel.com/customer/eloyalty-purchase-items/" +
            serverId +
            "/" +
            siteId +
            "/" +
            storeId +
            "/" +
            purchase.CheckNumber +
            "/" +
            purchase.SaleID;
          axios
            .get(url)
            .then((response) => {
              purchase.Items = response.data;
              temp.push(purchase);
            })
            .catch((error) => {});
        });
        setPurchases(temp);
      })
      .catch((error) => {});
  }, []);

  return (
    <Box
      sx={{ bgcolor: "background.paper" }}
      variant="fullWidth"
      style={{ padding: "0" }}
    >
      <AppBar
        position="fixed"
        style={{
          marginTop: "79px",
          background: "#5c5a5b",
          height: "70px",
          zIndex: "1",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Rewards" {...a11yProps(0)} style={{ height: "70px" }} />
          <Tab label="Purchases" {...a11yProps(1)} style={{ height: "70px" }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        background="#e5e3f1"
        padding="0"
        height="100vh"
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          style={{ background: "#e5e3f1", paddingTop: "20px 0 0 0" }}
        >
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            align="center"
            marginTop="150px"
            paddingTop="40px"
          >
            Current Available Rewards are:
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            component="div"
            align="center"
            style={{ fontWeight: "500" }}
          >
            ${(availableRewards/100).toFixed(2)}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            align="center"
          >
            STORE DOLLARS
          </Typography>
          <RewardsPoints />
          <Box
            sx={{ width: "100%" }}
            style={{
              backgroundColor: "rgb(204, 51, 51)",
              marginTop: "30px",
              borderRadius: "20px 20px 0 0",
              padding: "50px 0 100px 0",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              style={{ color: "#fff" }}
              align="center"
            >
              Receive{" "}
              <span style={{ color: "#f7e3af" }}> $5 in rewards dollar</span>
              <br /> for every $50 spent on purchases
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ color: "#fff" }}
              align="center"
            ></Typography>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              style={{ color: "#fff", margin: "20px 0" }}
              align="center"
            >
              No rewards found.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              component="div"
              style={{ color: "#fff" }}
              align="center"
            >
              <i>
                Note! There's a 5 minutes delay to sync and display data from
                our database after any transaction.
              </i>
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          style={{ background: "#e5e3f1" }}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            align="center"
            marginTop="150px"
            padding="40px 0 10px 0"
          >
            Purchases
          </Typography>
          {purchases &&
            purchases.map((purchase, i) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"panel" + i + "a-content"}
                    id={"panel" + i + "a-header"}
                  >
                    <Typography>Check # {purchase.CheckNumber}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {purchase.Items &&
                      purchase.Items.map((item) => {
                        return (
                          <Typography>
                            &nbsp;&nbsp;&nbsp;{item.Quantity}{" "}
                            {item.ReceiptDescription} ${item.ActualPrice}
                          </Typography>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          <Box
            sx={{ width: "100%" }}
            style={{
              backgroundColor: "rgb(204, 51, 51)",
              marginTop: "30px",
              borderRadius: "20px 20px 0 0",
              padding: "50px 20px 100px 20px",
              height: "100vh",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              style={{ color: "#fff" }}
              align="center"
            >
              Receive{" "}
              <span style={{ color: "#f7e3af" }}> $5 in rewards dollar</span>
              <br /> for every $50 spent on purchases
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ color: "#fff" }}
              align="center"
            ></Typography>
            <RewardsPurchases />
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
