import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { db } from "../../firebase/config";
import {
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { numberWithCommas } from "../../utils/numbersWithComma";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { NavBar } from "./navigation/NavBar";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

import { SampleNutrition } from "./SampleNutrition";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { setLoading } from "../../redux/publicReducer/actions";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CloseIcon from "@material-ui/icons/Close";
import { Cart } from "./cart";
export const ProductRating = ({ open, setOpen }) => {
  const [rating, setRating] = useState(0);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [sumRating, setSumRating] = useState(0);
  const [usersRated, setUsersRated] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const productRef = db
    .collection("admins")
    .doc(match.params.restaurantId)
    .collection("products")
    .doc(match.params.productId);

  // useEffect(() => {
  //   const unsub = productRef.onSnapshot((snapshot) => {
  //     const data = snapshot.data();

  //   });

  //   return unsub;
  // }, []);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Rate This Product</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(5).fill("star").map((star, index) => {
            return (
              <IconButton onClick={() => setRating(index + 1)}>
                {index + 1 > rating ? (
                  <StarBorderIcon
                    style={{ margin: !isMobile && "1rem" }}
                    fontSize="large"
                  />
                ) : (
                  <StarIcon
                    style={{ margin: !isMobile && "1rem", color: "#FFD700" }}
                    fontSize="large"
                  />
                )}
              </IconButton>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(setLoading(true));
            productRef
              .collection("ratings")
              .add({ rating })
              .then(() => {
                dispatch(setLoading(false));
                setOpen(false);
              });
          }}
        >
          Save Rating
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ProductDetails = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [modifiers, setModifiers] = useState([]);
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [openFavorite, setOpenFavorite] = useState(false);
  const [productRating, setProductRating] = useState(0);
  const productsRef = db
    .collection("admins")
    .doc(match.params.restaurantId)
    .collection("products");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [mode, setMode] = useState("ADD");
  const [numVotes, setNumVotes] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    const unsubscribe = db
      .collection("admins")
      .doc(match.params.restaurantId)
      .collection("products")
      .doc(match?.params?.productId)
      .onSnapshot((doc) => {
        if (doc.data()) {
          setProduct({ ...doc.data(), id: match.params.productId });
          setHasLoaded(true);
          setCategory(doc.data().category);
        }
      });

    return unsubscribe;
  }, [match?.params?.productId]);

  useEffect(() => {
    if (cartItems.length) {
      const findProduct = cartItems.find(
        (item) => item.id === match.params?.productId
      );
      if (findProduct) {
        setModifiers(findProduct.modifiers);
      }
    }
  }, [cartItems]);

  useEffect(() => {
    const unsub = productsRef
      .doc(match.params.productId)
      .collection("ratings")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          return;
        }
        let votes = snapshot.docs.length;
        let total = 0;
        snapshot.docs.forEach((doc) => {
          total += doc.data().rating;
        });

        setProductRating(parseFloat((total / votes).toFixed(1)));
      });

    return unsub;
  }, []);

  useEffect(() => {
    let unsub;

    if (category) {
      unsub = productsRef.onSnapshot((snapshot) => {
        let _relatedProducts = [];
        snapshot.docs.forEach((prod) => {
          console.log(prod.data().category?.label, category, "check");
          if (
            prod
              .data()
              .category.some((cat) =>
                category.some((c) => c.label === cat.label)
              ) &&
            prod.id !== match.params.productId
          ) {
            _relatedProducts.push({ ...prod.data(), id: prod.id });
          }
        });
        setRelatedProducts(_relatedProducts);
        console.log(_relatedProducts);
      });
    }

    return unsub;
  }, [category]);

  useEffect(() => {
    const checkIfFavorite = localStorage.getItem(
      `favorites_${match.params.restaurantId}`
    );
    if (checkIfFavorite) {
      const parsedFavorites = JSON.parse(checkIfFavorite);

      const isInFavorite = parsedFavorites.find(
        (favorite) => favorite.id === match.params.productId
      );

      if (isInFavorite) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [openFavorite]);

  console.log(modifiers);
  return (
    <React.Fragment>
      {isMobile && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0rem 6px",
          }}
        >
          <IconButton onClick={() => history.goBack()}>
            <CloseIcon color="primary" />
          </IconButton>
          <IconButton>
            <Cart />
          </IconButton>
        </div>
      )}
      <ProductRating open={open} setOpen={setOpen} />

      <>
        <Cart />
        {hasLoaded && (
          <Grid
            container
            alignItems="center"
            style={{
              minHeight: "auto",
              paddingBottom: "10vh",
              background: "white",
            }}
          >
            <Grid item xs={12} sm={6}>
              <img
                src={product.image}
                alt={product.productName}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "80%",
                  }}
                >
                  <Typography
                    variant={isMobile ? "h4" : "h3"}
                    style={{ fontWeight: "800", marginBottom: "1rem" }}
                  >
                    {product?.productName}
                  </Typography>
                  <Typography variant="h6">
                    $ {numberWithCommas(product.price)}
                  </Typography>
                  <div style={{ margin: "1rem 0rem" }}>
                    {product?.modifiers?.map((mod) => {
                      return (
                        <div>
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setModifiers([...modifiers, mod]);
                                  } else {
                                    setModifiers(
                                      modifiers.filter(
                                        (i) => i.name !== mod.name
                                      )
                                    );
                                  }
                                }}
                                checked={modifiers.some(
                                  (m) => m.name === mod.name
                                )}
                              />
                            }
                            label={
                              mod.price
                                ? `${mod.name} + $ ${numberWithCommas(
                                    mod.price
                                  )}`
                                : `${mod.name}`
                            }
                            labelPlacement="end"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontSize: ".875rem",
                        lineHeight: "1.7",
                        fontWeight: "300",
                        color: "#292929",
                        marginBottom: 0,
                      }}
                    >
                      {product?.description}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "1rem 0rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <StarIcon
                          style={{ color: "#FFD700", marginRight: "1rem" }}
                          onClick={() => setOpen(true)}
                          fontSize="large"
                        />
                      </div>
                      <div>
                        <Typography variant="h5">{productRating}</Typography>
                      </div>
                    </div>
                    <div>
                      {!isFavorite ? (
                        <IconButton onClick={() => setOpenFavorite(true)}>
                          <FavoriteBorderIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setMode("REMOVE");
                            setOpenFavorite(true);
                          }}
                        >
                          <FavoriteIcon style={{ color: "red" }} />
                        </IconButton>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        if (quantity > 1) {
                          setQuantity(quantity - 1);
                        }
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <div style={{ margin: "0rem 1rem" }}>{quantity}</div>
                    <IconButton onClick={() => setQuantity(quantity + 1)}>
                      <AddIcon />
                    </IconButton>
                  </div>

                  {(!isMobile && (
                    <>
                      <div
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            console.log(cartItems, product, "match");
                            const findProduct = cartItems.find(
                              (item) =>
                                item.id === product.id &&
                                item.productName === product.productName &&
                                item.modifiers.every((modifier) =>
                                  modifiers.find(
                                    (mod) => mod.name === modifier.name
                                  )
                                ) &&
                                (item?.modifiers?.length ===
                                  modifiers?.length ||
                                  !item.modifiers ||
                                  (!modifiers?.length &&
                                    !item?.modifiers?.length))
                            );
                            if (!findProduct) {
                              dispatch({
                                type: "SET_CART",
                                payload: [
                                  ...cartItems,
                                  {
                                    ...product,
                                    quantity: quantity,
                                    modifiers: modifiers,
                                  },
                                ],
                              });
                            } else {
                              let newCartItems = cartItems.map((item) => {
                                if (
                                  (item.id === findProduct.id &&
                                    item.modifiers.every((modifier) =>
                                      modifiers.find(
                                        (mod) => mod.name === modifier.name
                                      )
                                    ) &&
                                    item.modifiers.length ===
                                      modifiers.length) ||
                                  (item.id === findProduct.id &&
                                    item.modifiers.length === 0 &&
                                    modifiers.length === 0)
                                ) {
                                  return {
                                    ...item,
                                    quantity: item.quantity + quantity,
                                  };
                                }
                                return item;
                              });

                              dispatch({
                                type: "SET_CART",
                                payload: newCartItems,
                              });
                            }
                            toast.success(
                              `Added ${product.productName} to cart`
                            );
                          }}
                        >
                          <AddShoppingCartIcon /> Add to cart
                        </Button>
                      </div>
                    </>
                  )) || (
                    <Paper
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "fixed",
                        bottom: "0px",
                        left: "0px",
                        width: "100%",
                        zIndex: 1000,
                        padding: "1rem",
                      }}
                    >
                      <Grid container justify="center">
                        <Grid item xs={7}>
                          <Button
                            style={{
                              borderRadius: "20px",
                              textTransform: "none",
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              console.log(cartItems, product, "match");
                              const findProduct = cartItems.find(
                                (item) =>
                                  item.id === product.id &&
                                  item.productName === product.productName &&
                                  item.modifiers.every((modifier) =>
                                    modifiers.find(
                                      (mod) => mod.name === modifier.name
                                    )
                                  ) &&
                                  (item?.modifiers?.length ===
                                    modifiers?.length ||
                                    !item.modifiers ||
                                    (!modifiers?.length &&
                                      !item?.modifiers?.length))
                              );
                              if (!findProduct) {
                                dispatch({
                                  type: "SET_CART",
                                  payload: [
                                    ...cartItems,
                                    {
                                      ...product,
                                      quantity: quantity,
                                      modifiers: modifiers,
                                      expiration: Date.now() + 86400000,
                                    },
                                  ],
                                });
                              } else {
                                let newCartItems = cartItems.map((item) => {
                                  if (
                                    (item.id === findProduct.id &&
                                      item.modifiers.every((modifier) =>
                                        modifiers.find(
                                          (mod) => mod.name === modifier.name
                                        )
                                      ) &&
                                      item.modifiers.length ===
                                        modifiers.length) ||
                                    (item.id === findProduct.id &&
                                      item.modifiers.length === 0 &&
                                      modifiers.length === 0)
                                  ) {
                                    return {
                                      ...item,
                                      quantity: item.quantity + quantity,
                                    };
                                  }
                                  return item;
                                });

                                dispatch({
                                  type: "SET_CART",
                                  payload: newCartItems,
                                });
                              }
                              toast.success(
                                `Added ${product.productName} to cart`
                              );
                            }}
                          >
                            Add to Check
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </>

      <div style={{ padding: "3rem", backgroundColor: "#f9f9f9" }}>
        <Container>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
            variant={!isMobile ? "h4" : "h5"}
          >
            Nutritional Information
          </Typography>
          <Divider />
          <Accordion
            style={{ backgroundColor: "#f9f9f9", border: "none" }}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: "1rem 0rem" }}
            >
              <Typography
                style={{ fontWeight: "800" }}
                variant={isMobile ? "h6" : "h5"}
              >
                Nutritional Summary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SampleNutrition />
            </AccordionDetails>
          </Accordion>
          <Divider style={{ marginBottom: "1rem" }} />
          <Typography
            variant={!isMobile ? "h4" : "h5"}
            style={{
              margin: "3rem 0rem",
              fontWeight: "800",
              textAlign: "center",
            }}
          >
            Related Products
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              height: "300px",
              marginBottom: "1rem",
              padding: "2rem 0rem",
            }}
          >
            {relatedProducts.map((product) => {
              return (
                <Paper
                  style={{
                    padding: "1rem",
                    minHeight: "100%",
                    marginTop: "1rem",
                    marginRight: "1rem",
                    cursor: "pointer",
                    maxWidth: "250px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    history.push(
                      `/${match.params.restaurantId}/product/${product.productName}/${product.id}`
                    );
                  }}
                >
                  <img
                    alt={product.productName}
                    src={product.image}
                    style={{ maxWidth: "220px" }}
                  />
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {product.productName}
                  </Typography>
                  <Typography>${numberWithCommas(product.price)}</Typography>
                </Paper>
              );
            })}
          </div>
        </Container>
      </div>
      <FavoritesModal
        open={openFavorite}
        setOpen={setOpenFavorite}
        product={product}
        mode={mode}
        setMode={setMode}
      />
    </React.Fragment>
  );
};

export const FavoritesModal = ({
  open,
  setOpen,
  product,
  mode = "ADD",
  setMode,
}) => {
  const match = useRouteMatch();
  const [restaurantFavorites, setRestaurantFavorites] = useState([]);

  useEffect(() => {
    const existingFavorites = localStorage.getItem(
      `favorites_${match.params.restaurantId}`
    );

    if (existingFavorites) {
      setRestaurantFavorites(JSON.parse(existingFavorites));
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setMode("ADD");
        setOpen(false);
      }}
    >
      <DialogTitle>
        {mode === "ADD" ? "Add To Favorites" : "Remove From Favorites"}
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={product.image} style={{ maxWidth: "80%" }} />
          <Typography style={{ margin: "1rem 0rem", fontWeight: 800 }}>
            {product.productName}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (mode === "ADD") {
              localStorage.setItem(
                `favorites_${match.params.restaurantId}`,
                JSON.stringify([product, ...restaurantFavorites])
              );
              toast.success("Product Saved to Favorites");
              setOpen(false);
            } else {
              const removedFromFavorites = restaurantFavorites.filter(
                (fav) => fav.id !== match.params.productId
              );
              localStorage.setItem(
                `favorites_${match.params.restaurantId}`,
                JSON.stringify(removedFromFavorites)
              );
              toast.success("Product Removed From Favorites");
              setOpen(false);
            }
            setMode("ADD");
          }}
        >
          {mode === "ADD" ? "Save to favorites" : "Remove from favorites"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
