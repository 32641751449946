import "../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import LovingAllPeople from "../../../assets/images/loving-all-people-icon.png";
import CreatingSuperheroes from "../../../assets/images/creating-superheroes-icon.png";
import ExplodingTheBox from "../../../assets/images/exploding-the-box-icon.png";
import ImpactingTheWorld from "../../../assets/images/impacting-the-world-icon.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const About = () => {
  useEffect(() => {
    document.title = "We are a full service bar and restaurant in Eureka Montana.";
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            We are a full service bar and restaurant in Eureka Montana.
          </title>
          <link rel="canonical" href="/about" />
          <meta
            name="description"
            content="Not a typical chain restaurant without character. Completely MT authentic with deer antlers & video games and nice bar scene."
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <col className="whitespace"></col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
              We are a full service bar and restaurant in Eureka Montana.{" "}
              </Typography>
              <br />
              <h1 className="gold">
                Where The West
                <br></br>
                Is Still Wild
              </h1>
            </Col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                {" "}
                Classic American Food with BBQ in the Summertime. Our Story. Trappers Saloon gives you a taste of the old west.
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
            
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div class="about-info-left">
              <h2>Service Options:</h2>
              <ul className="paddingSpecial">
                <li>Dine-in</li>
                <li>Takeout</li>
                <li>No delivery</li>
              </ul>
              {/* <h2>Health & safety:</h2>
              <ul className="paddingSpecial">
                <li>Staff wear masks</li>
                <li>Staff required to disinfect surfaces between visits</li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <h2>
              Our Story
              </h2>
              <Typography variant="boby1">
              At Trapper’s Saloon, "Where The West Is Still Wild," we pride ourselves on tempting our guests with juicy hand-cut meat prepared the old-fashioned way on our smokers, ice cold beer served from our antique bar, and daily food specials inspired by seasonal and local offerings. Just 5 minutes north of the quaint town of Eureka, and 2 miles south of the Canadian border, the scenery from our location will take your breath away. In summer, our outdoor patios are blooming with hanging flower baskets, refreshing cocktails, ice cold beer, and the sound of horseshoes clanking as friends compete in a friendly round. Our outdoor stage lights up the night most summer weekends, with top artists from all over North America playing to a crowd dancing on the grass dance floor. In wintertime, you will find locals and tourists alike catching the big game on one of our 6 flat-screen tv’s, or enjoying hand-crafted cocktails and comfort food by our cozy fireplace.
              </Typography>
              <Typography variant="boby1">
                {""}
                <br />
                <br />
                We look forward to serving you, as you create amazing memories at Trapper’s Saloon!{" "}
              </Typography>
              <Typography variant="boby1">
                {" "}
                <br />
                <br />
                To partake in our wonderful dining experience, please{" "}
                <a
                  aria-label="Google Map"
                  href="https://goo.gl/maps/DYRXQLBnZn9PQg4q9"
                  className="red-link"
                  target="_blank"
                >
                  visit us
                </a>{" "}
                today!{" "}
              </Typography>
              <br />
              <h2 className="whitespace" >
                Features
              </h2>
              <Typography variant="boby1">
              Takeout, Outdoor Seating, Seating, Parking Available, Wheelchair Accessible, Serves Alcohol, Full Bar, Television, Table Service, Live Music
                {/* <br />
                <br />
                You can also hire us to cater your event. From pickup to
                full-service catering, we can do them all. We have also started
                a food truck that you can reserve for your event.
                <br />
                <br />
                We constantly update our menu and ensure that you get great
                service and are satisfied with our service every time you visit
                us! And if you have a food allergy, we can prepare our food
                according to your restrictions and specific needs. */}
              </Typography>
            </div>
            <div className="inner-about-gallery  ">
              <div className="col-lg-12 about-info-right">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Trappers Saloon About Us Image1"
                    src={aboutimg1}
                    alt="Trappers Saloon About Us Image1"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Trappers Saloon About Us Image2"
                    src={aboutimg2}
                    alt="Trappers Saloon About Us Image2"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Trappers Saloon About Us Image3"
                    src={aboutimg3}
                    alt="Trappers Saloon About Us Image3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default withRouter(About);
