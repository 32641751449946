import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import flatbreadpizzas from '../../../assets/images/flatbreadpizzas.jpg';

export default function FlatBreadPizzas() {
	return (
    <Container>
      <Row className="video">
        <Col lg={12}>
          <div className="center whitespace">
            {/* <h2 className="dark">Flat-Bread Pizza</h2> */}
            <img src={flatbreadpizzas} alt="Flat-Bread Pizza" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};