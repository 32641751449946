import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../../../App.css";
// import center from '../../../assets/css/custom.css';
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import LunchMenus from "../components/LunchMenus";
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import { Helmet, HelmetProvider } from "react-helmet-async";

export const LunchMenu = () => {
  return (

    <div className="LunchMenu">
      <MenuNavigation/>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lunch Menu: We are a full service bar and restaurant in Eureka Montana</title>
          <link rel="canonical" href="/contact" />
          <meta
            name="description"
            content="Why not take a break from your day and join us for lunch at Trappers Saloon? As a special treat, we're offering a complimentary domestic beer to accompany your meal. Come on down and enjoy a delicious lunch and a cold beer on us. We hope to see you soon and have a great day!"
          />
        </Helmet>
      </HelmetProvider>
      <div className="HeaderImg">
            <Container>
            <Row>
              <Col xs={12} className="showcase">
              <h2 className="title center gold">Lunch Menu</h2>
              </Col>
              <Col xs={10} className="center" >
              <h6 className="white">Why not take a break from your day and join us for lunch at Trappers Saloon? As a special treat, we're offering a complimentary domestic beer to accompany your meal. Come on down and enjoy a delicious lunch and a cold beer on us. We hope to see you soon and have a great day!</h6>
              </Col>
            </Row>
          </Container> 
      </div>
      <Container>
      <LunchMenus />
      </Container>
      <Footer/>
    </div>
  );
};
