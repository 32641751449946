import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={7}>
              <Typography className="white">
                {" "}
                Meals: Dinner | Lunch | Late Night
              </Typography>
              <h1 className="left gold blankspace">
                American Bar in Eureka
                <br></br>
              </h1>
              <Typography className="white blankspace">
                {" "}
                Come out Trappers Saloon and get a FREE domestic beer with your lunch!! We have football going on all our TVs !!! Have a great day everyone!! 
              </Typography>
              <Typography className="white blankspace">
                {" "}
                6040 US highway 93, Eureka, MT, United States, Montana
              </Typography>
              {/* <Button
                className="btn-red whitespace margin-bottom-10"
                href="/register"
              >
                Join our AJ's Club Program{" "}
              </Button> */}
            </Col>
            {/* <Col xs={12} lg={5} className="PhonerightImg"></Col> */}
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};