import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import KitchenManagerCard from "../components/KitchenManagerCard";
import KitchenStaffCard from "../components/KitchenStaffCard";
import CardMediaJoin from "../components/CardMediaJoin";
import BlankSpace from "../components/BlankSpace";
import FrontHouseCashierCard from "../components/FrontHouseCashierCard";
import { Footer } from "../components/Footer";
import { EventsSlider } from "../components/EventsSlider";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Events = () => {
  useEffect(() => {
    document.title = "Events: Phone Reservation";
  }, []);

  return (
    <div className="Events">
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Events: Phone Reservation</title>
          <link rel="canonical" href="/Events" />
          <meta
            name="description"
            content="We take reservations for lunch and dinner. To make a reservation. We do not book the bar area – we leave this for walk-in guests to ensure that we always offer some tables for those who have not booked."
          />
        </Helmet>
      </HelmetProvider>
      <EventsSlider />
      {/* <Container>
          <Row>
            <Col xs={12} className="whitespace">
              <h2 className="left dark">Step 1, watch this short video</h2>
            </Col> 
          </Row>
        </Container>
    <Col xs={11} sm={10} md={6} className="center"> 
            <CardMediaJoin/>
    </Col>  */}
      <Container>
        <Row>
          <Col xs={12} className="whitespace">
            <h2 className="center dark">Phone Reservation</h2>
          </Col>
          <col className="whitespace"></col>
          <Col xs={10} className="center title">
            <h6 className="">
              {" "}
              We take reservations for lunch and dinner. To make a reservation.
              We do not book the bar area – we leave this for walk-in guests to
              ensure that we always offer some tables for those who have not
              booked.
            </h6>
          </Col>
          <Col xs={10} className="center title">
            <h6 className="">
              {" "}
              Please call us at below number between 10am-6pm, Monday to Friday.
            </h6>
          </Col>
          <Col xs={10} className="center title">
            <Button size="large" variant="contained"
                aria-label="Call Phone Number +1 406-889-3400"
                href="tel:+1 406-889-3400"
                className="white"
                target="_blank"
              >
                Call at : +1 406-889-3400
            </Button>
          </Col>
          <col className="whitespace"></col>
        </Row>
      </Container>
      {/* <Col xs={11} sm={10} md={6} className="center"> 
            <KitchenManagerCard/>
    </Col>
    <Col xs={11} sm={10} md={6} className="center"> 
            <KitchenStaffCard/>
    </Col>
    <Col xs={11} sm={10} md={6} className="center"> 
            <FrontHouseCashierCard/>
    </Col> */}
      <BlankSpace />
      <Footer />
    </div>
  );
};
