import React from "react";
import Logo from "../../../assets/images/logo.png";
import CookieIcon from "../../../assets/images/cookie-icon.svg";
import CookieConsent from "react-cookie-consent";
import { FaHeart } from "react-icons/fa";
import { Container, Button, Col } from "react-bootstrap";
import { SocialConnect } from "./pages/homepage/SocialConnect";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

export const Footer = () => {
  const history = useHistory();
  return (
    <div>
      <SocialConnect />

      <div className="footer-area padding-top-40">
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/home">
                    {" "}
                    <img
                      width="100%"
                      height="100%"
                      title="Trappers Saloon Logo"
                      className="App-logo"
                      src={Logo}
                      alt="Trappers Saloon Logo"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title center">
                  <h3 className="title">
                    We are a full service bar & restaurant in Eureka, MT - <span>Where the west is still wild!
                    </span>
                  </h3>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <Button className="btn-red" href="/dinner-menu">
                  Order Now{" "}
                </Button>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography variant="h5" gutterBottom component="div">
                    Address
                  </Typography>
                  <p>
                    Trappers Saloon -  Bar & Grill <br />
                    <a
                      aria-label="Google Map"
                      href="https://goo.gl/maps/aHHgSpEkGwuvKfU66"
                      className="red-link"
                      target="_blank"
                    >
                      6040 US highway 93, Eureka, MT, United States, Montana
                    </a>
                    <br />
                    <a
                      aria-label="Call Phone Number +1 406-889-3400"
                      href="tel:+1 406-889-3400"
                      className="red-link"
                      target="_blank"
                    >
                      P: +1 406-889-3400
                    </a>
                  </p>
                  <a
                    href="https://goo.gl/maps/aHHgSpEkGwuvKfU66"
                    className="red-link"
                    target="_blank"
                  >
                    view location
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography variant="h5" gutterBottom component="div">
                    Service options
                  </Typography>
                  <p>Dine-in · Takeout · No delivery</p>
                  <a href="/lunch-menu" className="red-link">
                    order now
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography variant="h5" gutterBottom component="div">
                    Business Hours
                  </Typography>
                  <p>
                    <span>Thursday - Monday: 11:00AM - 10:00PM</span>
                    <br></br>
                    <span>Tuesday: 2:00PM - 8:00PM</span>
                    <br></br>
                    <span>Wednesday: 2:00PM - 2:00AM</span>
                  </p>
                  <a
                    aria-label="Call Phone Number +1 406-889-3400"
                    href="tel:+1 406-889-3400"
                    className="red-link"
                    target="_blank"
                  >
                    make a call
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget heart">
                  <p>
                    &copy; {new Date().getFullYear()} Trappers Saloon by
                    ServingIntel, made with{" "}
                    <span>
                      <FaHeart />{" "}
                    </span>{" "}
                    for a better web.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container className="footer">
          <p className="privacy">
            <a
              href="#"
              onClick={() => {
                history.push("/privacy-policy");
              }}
            >
              Privacy Policy
            </a>
          </p>
        </Container>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-red"
        style={{ background: "#000" }}
        buttonStyle={{ fontSize: "16px" }}
        expires={365}
      >
        <div className="center">
          <Col xs={3} className="padding-bottom-20 center">
            <img
              width="100%"
              height="100%"
              title="Accept all cookies Icon"
              src={CookieIcon}
              size="15"
              alt="Accept all cookies Icon"
              loading="lazy"
            />
          </Col>
          <p>
            <h3 className="center"> Your Privacy </h3>
          </p>
        </div>

        <Typography>
          Greetings! Our website uses cookies so we may better serve you. By
          clicking “Accept all cookies” and by continuing to browse our site you
          are agreeing to our{" "}
        </Typography>
        <Typography>
          <a
            href="#"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms and Conditions
          </a>
        </Typography>
      </CookieConsent>
    </div>
  );
};
