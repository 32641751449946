import ReactPlayer from "react-player";
import "../../../App.css";
import {
  Container,
  Card,
  Row,
  Col,
  h5,
  p,
  h2,
  div,
  Alert,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const OrderSlider = () => {
  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Browse, view, and track your order.
          </title>
          <link rel="canonical" href="/menu" />
          <meta
            name="description"
            content="At Trapper's Saloon, 'Where The West Is Still Wild,' we pride ourselves on tempting our guests with juicy hand-cut meat prepared the old-fashioned way"
          />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left gold">At Trapper's Saloon, "Where The West Is Still Wild"</h1>
              <Typography variant="body1" className="white">
                {" "}
                Trappers Saloon gives you a taste of the old west. We feature a full bar, lunch and dinner menus, casino and regularly have live entertainment.
              </Typography>
              <h2 className="left gold whitespace">
                Trappers Saloon |Traditional Western Bar | Eureka MT
              </h2>
              <Typography variant="body1" className="white">
                {" "}
                View the menu for Trappers Saloon and restaurants in Eureka MT
              </Typography>
            </Col>
            <Col sm={0} lg={4}>
              {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};