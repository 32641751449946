import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import SweetAlert2 from "react-sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { object } from "yup";

const storeName = process.env.REACT_APP_RESTAURANT_NAME;
const storeAddress = process.env.REACT_APP_RESTAURANT_ADDRESS;

export const DeliveryMap = (props) => {
  //---Map---//
  const [autoComplete, setAautoComplete] = useState();
  const [delAddress, setDelAddress] = useState("");
  const [chargePerMile, setChargePerMile] = useState([
    { miles: 1, charge: 4 },
    { miles: 2, charge: 4.5 },
    { miles: 3, charge: 5 },
    { miles: 4, charge: 5.5 },
    { miles: 5, charge: 6 },
  ]);
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const { orderProcessingDate, orderProcessingType, selectedAddress } =
    useSelector((state) => state.public);

  const mapRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVgArv2OMsGKqFcnSDvw38PUkWilzdiQo&libraries=places`;
    script.async = true;
    script.onload = () => {
      initMap();
    };
    document.body.appendChild(script);
  }, []);

  const getMeters = (miles) => {
    return miles * 1609.344;
  };
  const getMiles = (meters) => {
    return meters * 0.000621371192;
  };

  const initMap = () => {
    const center = { lat: 41.9038805, lng: -88.3372612 }; // New York City coordinates

    const map = new window.google.maps.Map(mapRef.current, {
      zoom: 14,
      center,
    });

    const geofence = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center,
      radius: getMeters(5), // 1000 meters
    });

    const input = inputRef.current;
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.bindTo("bounds", map);

    map.addListener("click", (event) => {
      const clickedLatLng = event.latLng;
      const distanceFromCenter =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          clickedLatLng,
          geofence.getCenter()
        );

      if (distanceFromCenter <= geofence.getRadius()) {
        alert("Inside geofence");
      } else {
        alert("Outside geofence");
      }
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        alert("No details available for input: " + place.name);
        return;
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      const addressLatLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      const distanceFromCenter =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(addressLatLng),
          geofence.getCenter()
        );

      if (distanceFromCenter <= geofence.getRadius()) {
        const miles = Math.round(parseFloat(getMiles(distanceFromCenter)));
        const deliveryCharge = chargePerMile.find((m) => m.miles == miles);
        props.updateDeliveryCharge(deliveryCharge.charge);

        try {
          console.log(place);
          selectedAddress.address = place.formatted_address;
          selectedAddress.city = place.address_components[2].long_name;
          selectedAddress.zipCode = place.address_components[7].long_name;
          dispatch(setSelectedAddress(selectedAddress));
          setDelAddress(place.formatted_address);
          setSwalProps({
            show: true,
            title: "Notification",
            text: "Thank you! Your address is within the delivery 5 miles radius.",
            icon: "success",
          });
        } catch (err) {
          console.log(err);

          setSwalProps({
            show: true,
            title: "Notification - Invalid Address",
            text: "Please specify Street Address.",
            icon: "warning",
          });
          props.setServiceOptionToPickup();
          setDelAddress("");
        }
      } else {
        setSwalProps({
          show: true,
          title: "Notification",
          text: "Sorry your location is outside the delivery 5 miles radius!",
          icon: "warning",
        });
      }
      props.setOpen(false);
    });
  };
  //---End Map---//

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100vh",
          marginTop: "2vh",
        }}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder="Type an address"
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 1,
            padding: "4px",
            borderRadius: "4px",
            border: "1px solid #aaa",
            width: "80%",
            maxWidth: "300px",
            height: "4vh",
          }}
        />
        <div id="map" ref={mapRef} style={{ width: "100%", height: "100vh" }} />
      </div>
      <SweetAlert2 {...swalProps} />
    </>
  );
};
