import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import dinnerMenu1 from '../../../assets/images/dinner-menu-page1.jpg';
import dinnerMenu2 from '../../../assets/images/dinner-menu-page2.jpg';

export default function DinnerMenus() {
	return (
    <Container>
      <Row className="video">
        <Col lg={12}>
          <div className="center whitespace">
            <h2 className="dark">Starters and Sides | Burgers</h2>
            <img src={dinnerMenu1} alt="Starters and Sides | Burgers" />
          </div>
        </Col>
        <Col lg={12}>
          <div className="center whitespace">
            <h2 className="dark">Dinners Entrees | From the Range | Baskets | Dessert</h2>
            <img src={dinnerMenu2} alt="Dinners Entrees | From the Range | Baskets | Dessert"/>
          </div>
        </Col>
      </Row>
    </Container>
  );
};