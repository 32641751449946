import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function DirectionCard() {
  return (
    <Card>
      <a aria-label="Google Map" href="https://goo.gl/maps/aHHgSpEkGwuvKfU66" className="none-link" target="_blank" ><CardActionArea className="color-scheme" >
        <CardMedia
          component="img"
          height="140"
          image="/static/get-direction.png"
          width="100%"
          alt="Get Direction"
          loading="lazy"
          title="Trappers Saloon Get Direction"          
        />
        <CardContent>
        <h2>Get Direction</h2>
          <Typography gutterBottom variant="h6" component="div">
          6040 US highway 93, Eureka,
            <br></br>  
            MT, United States, Montana
          </Typography>
        </CardContent>
      </CardActionArea></a>
    </Card>
  );
}