import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import lunchmenu1 from '../../../assets/images/lunch-menu-page1.jpg';
import lunchmenu2 from '../../../assets/images/lunch-menu-page2.jpg';

export default function LunchMenus() {
	return (
    <Container>
      <Row className="video">
        <Col lg={12}>
          <div className="center whitespace">
            <h2 className="dark">Starters and Sides | Salads and Soups</h2>
            <img src={lunchmenu1} alt="Starters and Sides | Salads and Soups" />
          </div>
        </Col>
        <Col lg={12}>
          <div className="center whitespace">
            <h2 className="dark">Deluxe Burgers | Sandwiches and Baskets</h2>
            <img src={lunchmenu2} alt="Deluxe Burgers | Sandwiches and Baskets" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};