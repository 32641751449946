import React, { useEffect, useState, Fragment } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  Drawer,
  ImageList,
  ImageListItem,
  IconButton,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from "react-phone-number-input";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "./navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeExpiredItems, setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DayPicker,
  SingleDatePicker,
  isInclusivelyAfterDay,
} from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { db } from "../../firebase/config";
import CARDICONS from "../../assets/CARDICONS.svg";
import VISA from "../../assets/visa.png";
import AMEX from "../../assets/AMEX.png";
import MASTERCARD from "../../assets/mastercard.png";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useCookies } from "react-cookie";
import GIFTCARDS from "../../assets/GIFTCARD.png";
import COIN from "../../assets/COIN.png";

import { rewardsService } from "../../services";
import { RewardsAsPayment } from "./rewards";
import { GiftCardAsPayment } from "./giftcards";
import axios from "axios";

import PaymentNested from "./components/PaymentNested";
import { MenuNavigation } from "./components/MenuNavigation";
import { DeliveryMap } from "./DeliveryMapV2";

import BlankSpace from "./components/BlankSpace";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Swal from "sweetalert2";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;

const enablePickup = process.env.REACT_APP_PICKUP;
const enableDineIN = process.env.REACT_APP_DINE_IN;
const enalbeDelivery = process.env.REACT_APP_DELIVERY;
const serviceCharge = process.env.REACT_APP_SERVICE_FEE;

//--Time Conditions--//
const startHour = 11;
const startTime = 660;
const endHour = 19;
const endTime = 1220;
const endMinutes = 45;

const getCookie = (key) => {
  var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? keyValue[2] : null;
};

export const Checkout = () => {
  const [openTips, setOpenTips] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableCheckoutMessage, setDisableCheckoutMessage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openSelectAddress, setOpenSelectAddress] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
  } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const [updateTime, setUpdateTime] = useState("");

  useEffect(() => {
    var sourceUpdateTime = new EventSource(
      "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
    );
    sourceUpdateTime.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
          setUpdateTime(u_items[0].time);
          sessionStorage.setItem("update_date_time", u_items[0].time);
        }
      }
    };
  }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/checkout-enabled/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        if (response.data.OnOff < 1) {
          Swal.fire({
            title: "Store Message",
            text: response.data.eCommerceDisplayMessage,
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/");
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };
  //For modal style
  // useEffect(() => {
  //   const url =
  //     "https://ecommv2.servingintel.com/customer/checkout-enabled/" +
  //     serverId +
  //     "/" +
  //     siteId;
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       if (response.data.OnOff < 1) {
  //         setShowModal(true);
  //         setDisableCheckoutMessage(response.data.eCommerceDisplayMessage);
  //       }
  //     })
  //     .catch((error) => {});
  // },[]);

  useEffect(() => {
    //set date as current date
    dispatch(setOrderProcessingDate(moment()));

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY").add(1, "days")));
    }

    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  //console.log(cookiePayment, "COOKIE");

  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    } else {
      const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        address: selectedAddress ? selectedAddress.address : "",
        city: selectedAddress ? selectedAddress.city : "",
        zipCode: selectedAddress ? selectedAddress.zipCode : "",
        phone: "",
      };

      dispatch(setSelectedAddress(initialValues));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const itemId = response.data.itemId;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept || item.ItemID == itemId) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  useEffect(() => {
    if (orderProcessingType !== 2 || deliveryCharge == 0) {
      setDeliveryCharge(0);
    }
  }, [orderProcessingType, deliveryCharge]);

  const updateDeliveryCharge = (charge) => {
    setDeliveryCharge(charge);
  };

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const itemId = response.data.itemId;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept || item.ItemID == itemId) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      {/* Checkout Disable Modal */}
      {/* <Modal
        show={showModal}
        style={{ "z-index": "99999" }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Checkout Reminder!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <p>{disableCheckoutMessage}</p>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Grid container justify="center" className="content">
            <Button
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                padding: "0rem 1rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                fontSize: "large",
                width: "50%",
              }}
              onClick={() => {
                setShowModal(false);
                history.push("/");
              }}
            >
              OK
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal> */}
      <MenuNavigation />
      <div className="HeaderImg">
        <Container>
          <Row>
            <Col xs={12}>
              <h1 className="blankspace center gold">
                Your Payment and Billing Details
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Checkout: Accept Payments Online</title>
          <link rel="canonical" href="/checkout" />
          <meta
            name="description"
            content="We offer mini takeaway pies as well as occasionally, full-size ones."
          />
        </Helmet>
      </HelmetProvider>
      <NavBar />
      <Container>
        <Typography
          component="h2"
          variant="subtitle1"
          style={{ fontWeight: 700 }}
          gutterBottom
        >
          MY ORDER{" "}
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              fontSize: "0.8rem",
            }}
            onClick={() => history.push("/menu")}
          >
            Add More
          </Button>
        </Typography>
        <Box height="1rem" />
        <Typography gutterBottom style={{ fontWeight: 700 }}>
          Promo Code
        </Typography>
        <Box display="flex" position="relative">
          <input
            className="couponcode"
            style={{
              width: "30%",
              padding: "14px",
              border: "1px solid #bebebe",
              fontSize: "1rem",
            }}
            placeholder="Enter coupon code"
            value={tempPromoCode}
            onChange={(e) => setTempPromoCode(e.target.value)}
          />
          <Button
            id="coupon_btn"
            style={{ position: "absolute", right: "5px", top: "1px" }}
            onClick={() => {
              applyCoupon();
            }}
          >
            Apply
          </Button>
        </Box>
        <br />
        <Grid container spacing={1}>
          {cartItems.length === 0 && (
            <Typography
              style={{ textAlign: "center", width: "100%", color: "#bebebe" }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          )}
          {cartItems.map((item) => {
            const modifiers = item.modifiers.sort(
              (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
            );
            return (
              <React.Fragment key={item.ItemID}>
                <TableContainer
                  id="checkoutTable"
                  component={Paper}
                  style={{ backgroundColor: "#EAEAEA" }}
                >
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="150px" className="checkcell01">
                          <img
                            // alt={item.FileName}
                            height="100%"
                            width="100%"
                            // title={item.FileName}
                            loading="lazy"
                            src={
                              item.FileName && item.FileName.length > 0
                                ? siteImgSrc + item.FileName
                                : null
                            }
                            style={{ borderRadius: "10px" }}
                          />
                        </TableCell>

                        <TableCell className="checkcell02">
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{ fontWeight: 700 }}
                            gutterBottom
                          >
                            {item.ItemName}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" className="checkcell03">
                          <Grid container justify="flex-end">
                            <Box
                              border="1px solid #cb4629"
                              borderRadius="4px"
                              display="flex"
                              alignItems="center"
                              padding="0"
                              color="white"
                              style={{ backgroundColor: "#cb4629" }}
                            >
                              <Button style={{ minWidth: "40px" }}>
                                <RemoveIcon
                                  fontSize="normal"
                                  onClick={() => {
                                    if (item.quantity > 1) {
                                      const newCartItems = cartItems.map(
                                        (cartItem) => {
                                          if (cartItem.ItemID === item.ItemID) {
                                            return {
                                              ...item,
                                              quantity: item.quantity - 1,
                                            };
                                          }

                                          return cartItem;
                                        }
                                      );

                                      dispatch({
                                        type: "SET_CART",
                                        payload: newCartItems,
                                      });
                                    } else {
                                      const filterCartItems = cartItems
                                        .map((i) => i)
                                        .filter((j) => j !== item);
                                      dispatch({
                                        type: "SET_CART",
                                        payload: filterCartItems,
                                      });
                                    }
                                  }}
                                />
                              </Button>
                              <Box fontSize="normal" margin="0">
                                {item.quantity}
                              </Box>
                              <Button style={{ minWidth: "40px" }}>
                                <AddIcon
                                  fontSize="normal"
                                  onClick={() => {
                                    const newCartItems = cartItems.map((i) => {
                                      if (i === item) {
                                        return {
                                          ...i,
                                          quantity: i.quantity + 1,
                                        };
                                      }
                                      return i;
                                    });

                                    dispatch({
                                      type: "SET_CART",
                                      payload: newCartItems,
                                    });
                                  }}
                                />
                              </Button>
                            </Box>
                          </Grid>
                        </TableCell>

                        <TableCell align="right" className="checkcell04">
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{ fontWeight: 700, paddingRight: "4.5px" }}
                            gutterBottom
                          >
                            $ {numberWithCommas(item.DefaultPrice / 100)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell className="checkcell01"></TableCell>

                        <TableCell className="checkcell02">
                          {item.modifiers ? (
                            <Typography variant="subtitle2"></Typography>
                          ) : null}

                          {item.modifiers
                            ? modifiers.map((mod) => (
                                <>
                                  <Typography>{mod.ItemDescription}</Typography>
                                </>
                              ))
                            : null}
                        </TableCell>

                        <TableCell
                          align="center"
                          className="checkcell03"
                        ></TableCell>

                        <TableCell align="right" className="checkcell04">
                          {item.modifiers
                            ? modifiers.map((mod) => (
                                <>
                                  <Typography style={{ paddingRight: "8.5px" }}>
                                    $ {numberWithCommas(mod.DefaultPrice / 100)}
                                  </Typography>
                                </>
                              ))
                            : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            );
          })}
        </Grid>
        <Box height="1rem" />
        <TableContainer id="checkoutTotal" component={Paper}>
          <Table>
            <TableBody>
              {/* -----------Sub Total-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{ fontWeight: 700 }}
                    gutterBottom
                  >
                    Subtotal
                  </Typography>
                </TableCell>

                <TableCell
                  className="totalcell02"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>

                <TableCell
                  className="totalcell03"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>

                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{ fontWeight: 700, marginRight: "1px" }}
                    gutterBottom
                  >
                    $ {numberWithCommas(totalPriceItems)}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* -----------Sub Total-----------*/}

              {/* -----------Promo Code-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{ padding: "2.5px 16px" }}
                >
                  {promoCode && discount > 0 ? (
                    <>
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{ fontWeight: 700 }}
                        gutterBottom
                      >
                        Promo Code{" "}
                        {discountPercentage != 0
                          ? "(-" + discountPercentage + "%)"
                          : ""}
                        {promoCode}
                        <RemoveCircleIcon
                          style={{ color: "black" }}
                          fontSize="large"
                          onClick={() => {
                            dispatch(setPromoCode(""));
                            setTempPromoCode("");
                          }}
                        />
                      </Typography>
                    </>
                  ) : null}
                </TableCell>
                <TableCell
                  className="totalcell02"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="center"
                  className="totalcell03"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{ padding: "2.5px 16px" }}
                >
                  {promoCode && discount > 0 ? (
                    <>
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{ fontWeight: 700, marginRight: "1px" }}
                        gutterBottom
                      >
                        -$ {numberWithCommas(discount)}
                      </Typography>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
              {/* -----------Promo Code-----------*/}

              {/* -----------Tax-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{ fontWeight: 700 }}
                    gutterBottom
                  >
                    Sales Tax ({tax * 100}%)
                  </Typography>
                </TableCell>
                <TableCell
                  className="totalcell02"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="center"
                  className="totalcell03"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{ fontWeight: 700, marginRight: "1px" }}
                    gutterBottom
                  >
                    ${" "}
                    {numberWithCommas(
                      parseFloat((totalPriceItems - discount) * tax)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* -----------Tax-----------*/}

              {/* -----------Delivery Charge-----------*/}
              {deliveryCharge > 0 ? (
                <TableRow>
                  <TableCell
                    className="totalcell01"
                    style={{ padding: "2.5px 16px" }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      Delivery Charge
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="totalcell02"
                    style={{ padding: "2.5px 16px" }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className="totalcell03"
                    style={{ padding: "2.5px 16px" }}
                  ></TableCell>
                  <TableCell
                    align="right"
                    className="totalcell04"
                    style={{ padding: "2.5px 16px" }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{ fontWeight: 700, marginRight: "1px" }}
                      gutterBottom
                    >
                      $ {numberWithCommas(parseFloat(deliveryCharge))}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
              {/* -----------Delivery Charge-----------*/}

              {/* -----------Tip-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Box
                    // border="1px solid #715b0d"
                    borderRadius="3px"
                    padding="1px"
                    display="flex"
                    alignItems="left"
                    color="white"
                    // style={{ backgroundColor: "#715b0d" }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: "black",
                        paddingTop: "8px",
                      }}
                      gutterBottom
                    >
                      Tip
                    </Typography>
                    <Button
                      style={{
                        textTransform: "none",
                        marginLeft: 15,
                        color: "gray",
                        fontSize: "0.8rem",
                      }}
                      onClick={() => setOpenTips(true)}
                    >
                      Change
                    </Button>
                  </Box>
                </TableCell>
                <TableCell
                  className="totalcell02"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="center"
                  className="totalcell03"
                  style={{ padding: "2.5px 16px" }}
                ></TableCell>
                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{ padding: "2.5px 16px" }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{ fontWeight: 700, marginRight: "-2px" }}
                    gutterBottom
                  >
                    $ {numberWithCommas(tips)}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* -----------Tip-----------*/}

              <TableRow style={{ backgroundColor: "#C0C0C0" }}>
                <TableCell className="totalcell01">
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{ fontWeight: 700 }}
                  >
                    Amount Due
                  </Typography>
                </TableCell>
                <TableCell className="totalcell02"></TableCell>
                <TableCell align="center" className="totalcell03"></TableCell>
                <TableCell align="right" className="totalcell04">
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{ fontWeight: 700, marginRight: "-3.5px" }}
                  >
                    ${" "}
                    {numberWithCommas(
                      parseFloat(tips) +
                        parseFloat(deliveryCharge) +
                        parseFloat(totalPriceItems - discount) +
                        parseFloat((totalPriceItems - discount) * tax)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BlankSpace />
        <Typography gutterBottom style={{ fontWeight: 700 }}>
          Message to Kitchen
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {/* <TextareaAutosize
              style={{ width: "100%" }}
              minLength={10}
              maxLength={300}
              rowsMin={3}
              rowsMax={3}
              cols={50}
            /> */}
            <Form provider={methods} onSubmit={handleSubmit}>
              <Input
                onChange={setKitchenMsg}
                variant="outlined"
                name="message"
                placeholder="Enter special instructions"
                autoComplete="off"
              />
            </Form>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Typography
            style={{ fontWeight: 700 }}
            component="h4"
            variant="subtitle1"
          >
            Selected Service Option<span style={{ color: "red" }}> *</span>
          </Typography>
          <Button
            style={{
              textTransform: "none",
              marginLeft: 5,
              color: "#000",
              padding: "0px",
              "margin-left": "1rem",
              // "font-style": "italic",
            }}
            onClick={() => setOpenServiceOptions(true)}
          >
            Change
          </Button>
        </Grid>
        <Typography>
          {`${
            orderProcessingType == 0
              ? "Pickup"
              : orderProcessingType == 1
              ? "Dine-In"
              : "Delivery"
          } - 
          ${
            orderProcessingDate
              ? moment(orderProcessingDate).format("MMMM DD, YYYY h:mm A")
              : "Please Select Date & Time"
          }`}
        </Typography>

        {/* <Typography style={{ fontWeight: 700 }} variant="subtitle1">
          Pick-up is only available at the St. Charles location.
        </Typography> */}

        <br />
        <Grid container>
          <Typography
            style={{ fontWeight: 700 }}
            component="h5"
            variant="subtitle1"
          >
            Customer Details<span style={{ color: "red" }}> *</span>
          </Typography>
          <Box
            width="100%"
            borderRadius="10px"
            padding="1rem"
            onClick={() => {
              setOpenSelectAddress(true);
            }}
            border="1px solid #bebebe"
          >
            {(!selectedAddress && (
              <>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Add Details
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {user?.name}
                </Typography>
              </>
            )) || (
              <>
                <Typography style={{ color: "gray" }} variant="caption">
                  Tap to Change Details
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.first_name
                    ? selectedAddress.first_name +
                      " " +
                      selectedAddress.last_name +
                      ", " +
                      selectedAddress.email
                    : null}
                </Typography>

                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.first_name
                    ? selectedAddress.address +
                      ", " +
                      selectedAddress.city +
                      ", " +
                      selectedAddress.zipCode
                    : null}
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.phone}
                </Typography>
              </>
            )}
          </Box>
        </Grid>

        <PaymentSelector setOpenUserCards={setOpenUserCards} />
        <Box height="1rem" />
        {selectedPayment && (
          <>
            <Typography style={{ fontWeight: 700 }} gutterBottom>
              Selected Mode of Payment
            </Typography>
            <span>
              <Typography>{selectedPayment}</Typography>
            </span>
          </>
        )}

        <Box height="1rem" />
        <Box
          bottom="0px"
          // position="fixed"
          left="0px"
          width="100%"
        >
          <Button
            style={{
              display: "none",
              padding: "11px 0rem",
              borderRadius: "10px",
              backgroundColor: cartItems.length ? "#ff0000" : "white",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "bold",
              color: cartItems.length ? "#fff" : "#000",
              letterSpacing: "1px",
            }}
            fullWidth
            variant="contained"
            disabled={!cartItems.length}
            onClick={() => {
              if (
                ["Gift Card", "Rewards"].includes(selectedPayment) &&
                selectedPayment != ""
              ) {
                rewardsService.handleRewardsPayment(orderSession);
                alert("Order payment success!");
              }
            }}
          >
            Place order
          </Button>
        </Box>
      </Container>
      <TipsDrawer open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawer
        open={openUserCards}
        setOpen={setOpenUserCards}
        tax={tax}
        deliveryCharge={deliveryCharge}
      />
      <ServiceOptionsDrawer
        updateDeliveryCharge={updateDeliveryCharge.bind(this)}
        open={openServiceOptions}
        setOpen={setOpenServiceOptions}
      />
      <SelectAddressDrawer
        open={openSelectAddress}
        setOpen={setOpenSelectAddress}
      />

      {/* <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Checkout Reminder!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>
            <p>Pick-up is only available at the St. Charles location.</p>
            <p>
              For pickup orders at our new South Elgin location, please call the
              store at 707–754–3377.
            </p>
            <p>Thank You.</p>
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  );
};

export const ServiceOptionsDrawer = ({
  open,
  setOpen,
  updateDeliveryCharge,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType } = useSelector(
    (state) => state.public
  );
  const [cookie, setCookie, removeCookie] = useCookies([""]);

  const [address, setAddress] = useState();
  const [coords, setCoords] = useState();

  const updateTime = (e) => {
    //console.log(e, "E");
    // dispatch(setOrderProcessingDate(moment()))
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");

      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      dispatch(setOrderProcessingDate(defautTimeAndDate));
    }
  };

  const setServiceOptionToPickup = () => {
    dispatch(setSelectedServiceOption(0));
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  };

  useEffect(() => {
    if (timeConstant.length > 0) {
      timeConstant.map((time, i) => {
        if (i === 0) {
          updateTime({
            label: time.name,
            value: time.code,
          });
        }
      });
    }
  }, [timeConstant]);

  useEffect(() => {
    var tempTimeConstant = [];
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;
    var hour = d.getHours(); //get current hour
    var min = d.getMinutes(); //get current min
    var cdate = d.getDate();
    var cyear = d.getFullYear();
    var cmonth = d.getMonth();

    var interval = 15; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ["AM", "PM"]; // AM-PM

    //--round time--//
    var date_r = new Date(d.getTime());

    // date_r.setMinutes(((((date_r.getMinutes() + 7.5) / 15) | 0) * 15) % 60);
    date_r.setMinutes(date_r.getMinutes() + interval);
    //date_r.setHours(date_r.setMinutes(date_r.getMinutes() + 8));
    //--end round time--//

    if (selectedDate) {
      //loop to increment the time and push results in array
      for (var i = 0; tt <= 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const hh_am_pm = hh > 12 ? hh : ((hh + startHour) % 12) + 1;
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        times[i] =
          ("0" + (((hh + startHour) % 12) + 1)).slice(-2) +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh_am_pm / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

        tt = tt + interval;

        if (tt <= endTime) {
          //console.log(hh + "=" + hour);
          //console.log(mm + "=" + (min + 15));
          if (
            selectedDate.getDate() == cdate &&
            selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear
          ) {
            //--set time to current option--//
            let timeOption = new Date(d.getTime());
            timeOption.setHours(hh);
            timeOption.setMinutes(mm);

            if (
              //(hh >= hour && (mm >= min + 15 || mm <= min - 30)) ||
              (hh >= hour && timeOption.getTime() > date_r.getTime()) ||
              hour < startHour
            ) {
              tempTimeConstant.push({ code: times[i], name: times[i] });
              // console.log("push:" + times[i]);
            }
          } else {
            tempTimeConstant.push({ code: times[i], name: times[i] });
          }
        }
      }
      setTimeConstant(tempTimeConstant);

      if (
        selectedDate.getDate() == cdate &&
        selectedDate.getMonth() == cmonth &&
        selectedDate.getFullYear() == cyear &&
        (hour > endHour || (hour == endHour && min >= endMinutes))
      ) {
        //  alert("Store is closed. You can order for next day");
        dispatch(
          setOrderProcessingDate(
            moment(selectedDate, "MM-DD-YYYY").add(0, "days")
          )
        );
        setDate(selectedDate);
      }
    }
  }, [date]);

  // let timeConstant = [
  //   { code: "", name: "Select Pickup Time" },
  //   { code: "11:00 am", name: "11:00 am" },
  //   { code: "11:15 am", name: "11:15 am" },
  //   { code: "11:30 am", name: "11:30 am" },
  //   { code: "11:30 am", name: "11:45 am" },
  //   { code: "12:00 pm", name: "12:00 pm" },
  //   { code: "12:15 pm", name: "12:15 pm" },
  //   { code: "12:30 pm", name: "12:30 pm" },
  //   { code: "01:00 pm", name: "01:00 pm" },
  //   { code: "01:15 pm", name: "01:15 pm" },
  //   { code: "01:30 pm", name: "01:30 pm" },
  //   { code: "02:00 pm", name: "02:00 pm" },
  //   { code: "02:15 pm", name: "02:15 pm" },
  //   { code: "02:30 pm", name: "02:30 pm" },
  //   { code: "03:00 pm", name: "03:00 pm" },
  //   { code: "03:15 pm", name: "03:15 pm" },
  //   { code: "03:30 pm", name: "03:30 pm" },
  //   { code: "04:00 pm", name: "04:00 pm" },
  //   { code: "04:15 pm", name: "04:15 pm" },
  //   { code: "04:30 pm", name: "04:30 pm" },
  //   { code: "05:00 pm", name: "05:00 pm" },
  //   { code: "05:30 pm", name: "05:30 pm" },
  //   { code: "06:00 pm", name: "06:00 pm" },
  //   { code: "06:30 pm", name: "06:30 pm" },
  //   { code: "07:00 pm", name: "07:00 pm" },
  //   { code: "07:30 pm", name: "07:30 pm" },
  //   { code: "08:00 pm", name: "08:00 pm" },
  //   { code: "08:30 pm", name: "08:30 pm" },
  //   { code: "09:00 pm", name: "09:00 pm" },
  // ];

  // console.log(orderProcessingDate, "first load");
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box
        width="100%"
        padding="1rem"
        height="80vh"
        position="relative"
        overflow="auto"
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 700 }}
          gutterBottom
        >
          Select Service Option
        </Typography>
        <Typography gutterBottom>
          {`${
            orderProcessingType == 0
              ? "Pickup"
              : orderProcessingType == 1
              ? "Dine-In"
              : "Delivery"
          } - ${moment(orderProcessingDate).format("MMMM DD, YYYY hh:mm a")}`}
        </Typography>
        <Box height="1rem" />
        <Grid container spacing={2}>
          {enablePickup == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                // width="100%"
                display="flex"
                alignItems="center"
                borderRadius="10px"
                justifyContent="center"
                border={orderProcessingType != 0 && "1px solid #bebebe"}
                color={orderProcessingType == 0 && "white"}
                bgcolor={orderProcessingType == 0 && "#cb4629"}
                onClick={() => dispatch(setSelectedServiceOption(0))}
              >
                <Typography variant="h6">Pickup</Typography>
              </Box>
            </Grid>
          ) : null}

          {enableDineIN == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                // width="100%"
                display="flex"
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={orderProcessingType != 1 && "1px solid #bebebe"}
                color={orderProcessingType == 1 && "white"}
                bgcolor={orderProcessingType == 1 && "#cb4629"}
                onClick={() => dispatch(setSelectedServiceOption(1))}
              >
                <Typography variant="h6">Dine-In</Typography>
              </Box>
            </Grid>
          ) : null}

          {enalbeDelivery == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                // width="100%"
                display="flex"
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={orderProcessingType != 2 && "1px solid #bebebe"}
                color={orderProcessingType == 2 && "white"}
                bgcolor={orderProcessingType == 2 && "#cb4629"}
                onClick={() => dispatch(setSelectedServiceOption(2))}
              >
                <Typography variant="h6">Delivery</Typography>
              </Box>
            </Grid>
          ) : null}

          {/* <div
            className="map-content"
            style={{ height: "290px", width: "100%" }}
          ></div> */}
        </Grid>
        <Box height="1rem" />

        <Box height="1rem" />
        <Grid>
          <Box
          // width="100%"
          >
            <Typography className="content" style={{ fontWeight: 700 }}>
              Select date of{" "}
              {orderProcessingType == 0
                ? "Pickup"
                : orderProcessingType == 1
                ? "Dine-In"
                : "Delivery"}
            </Typography>
            <SingleDatePicker
              width="100%"
              className="content"
              focused={focused}
              numberOfMonths={1}
              date={orderProcessingDate}
              id="date_input"
              onDateChange={(date) => {
                dispatch(setOrderProcessingDate(date));
                setFocused(false);
                setDate(date);
              }}
              placeholder="Select date"
              orientation="horizontal"
              onFocusChange={(data) => {
                setFocused(data.focused);
              }}
              isDayBlocked={(day) => {
                const d = new Date(day);
                if (d.getDate() === 4 && d.getMonth() === 6) {
                  return true;
                }
                if (d.getDay() == 0) {
                  return true;
                }
              }}
            />
          </Box>
        </Grid>
        <Box height="1rem" />
        <Grid container>
          <Box width="100%">
            <Typography className="content" style={{ fontWeight: 700 }}>
              Select time of{" "}
              {orderProcessingType == 0
                ? "Pickup"
                : orderProcessingType == 1
                ? "Dine-In"
                : "Delivery"}
            </Typography>
            <Select
              options={timeConstant.map((time) => {
                return {
                  label: time.name,
                  value: time.code,
                };
              })}
              defaultValue={timeConstant.map((time, i) => {
                if (i === 0) {
                  return {
                    label: time.name,
                    value: time.code,
                  };
                }
              })}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  fontSize: "1.25rem",
                }),

                singleValue: (provided, state) => ({
                  ...provided,
                  fontSize: "1.25rem",
                }),
              }}
              onChange={updateTime}
            />
            {orderProcessingType == 2 ? (
              <DeliveryMap
                updateDeliveryCharge={updateDeliveryCharge.bind(this)}
                setOpen={setOpen.bind(this)}
                setServiceOptionToPickup={setServiceOptionToPickup}
              />
            ) : null}
          </Box>
        </Grid>
        <Box
          position="absolute"
          bottom="auto"
          left="0px"
          padding="1rem 0 0 0"
          width="100%"
        >
          <Button
            fullWidth
            elevation={0}
            style={{
              textTransform: "none",
              padding: "0rem 1rem",
              borderRadius: "10px",
              border: "1px solid #bebebe",
              backgroundColor: "#cb4629",
              fontSize: "1.25rem",
              width: "100%",
            }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            DONE
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export const SelectAddressDrawer = ({ open, setOpen }) => {
  const history = useHistory();
  const [addresses, setAddresses] = useState([]);
  const dispatch = useDispatch();
  const { user, selectedAddress, orderProcessingType } = useSelector(
    (state) => state.public
  );

  const initialValues = {
    first_name: selectedAddress ? selectedAddress.first_name : "",
    last_name: selectedAddress ? selectedAddress.last_name : "",
    email: selectedAddress ? selectedAddress.email : "",
    address: selectedAddress ? selectedAddress.address : "",
    city: selectedAddress ? selectedAddress.city : "",
    zipCode: selectedAddress ? selectedAddress.zipCode : "",
    phone: selectedAddress ? selectedAddress.phone : "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    address:
      orderProcessingType !== 2
        ? Yup.string().required("Address is required")
        : Yup.string(),
    city:
      orderProcessingType !== 2
        ? Yup.string().required("City is required")
        : Yup.string(),
    zipCode:
      orderProcessingType !== 2
        ? Yup.string().required("ZipCode is required")
        : Yup.string(),
    phone: Yup.string().required("Phone number is required"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors, reset } = methods;

  const handleSubmit = async (d) => {
    console.log(d);

    selectedAddress.first_name = d.first_name;
    selectedAddress.last_name = d.last_name;
    selectedAddress.email = d.email;
    selectedAddress.address =
      orderProcessingType !== 2 ? d.address : selectedAddress.address;
    selectedAddress.city =
      orderProcessingType !== 2 ? d.city : selectedAddress.city;
    selectedAddress.zipCode =
      orderProcessingType !== 2 ? d.zipCode : selectedAddress.zipCode;
    selectedAddress.phone = d.phone;
    dispatch(setSelectedAddress(selectedAddress));
    reset(initialValues);
    setOpen(false);
  };

  // useEffect(() => {
  //   const unsub = db
  //     .collection("users")
  //     .doc(user.id)
  //     .collection("address")
  //     .onSnapshot((snapshot) => {
  //       setAddresses(
  //         snapshot.docs.map((doc) => {
  //           return {
  //             ...doc.data(),
  //             id: doc.id,
  //           };
  //         })
  //       );
  //     });

  //   return unsub;
  // }, []);

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="bottom"
      className="CheckoutDrawer"
    >
      <React.Fragment>
        <IconButton
          onClick={() => setOpen(false)}
          style={{ borderRadius: "1px", backgroundColor: "gray" }}
        >
          <ArrowBackIcon style={{ fontSize: "2rem", fill: "whitesmoke" }} />
        </IconButton>
        <Container style={{ position: "relative", minHeight: "90vh" }}>
          {addresses.map((add) => {
            return (
              <Box
                padding="1rem"
                borderRadius="10px"
                width="100%"
                margin="1rem 0rem"
                border="1px solid #bebebe"
                onClick={() => {
                  db.collection("users").doc(user.id).update({ address: add });
                  history.goBack();
                }}
              >
                <Typography variant="subtitle1" color="primary">
                  {add.address + ", " + add.city + ", " + add.zipCode}
                </Typography>
                <Typography variant="caption">{add.phone}</Typography>
              </Box>
            );
          })}
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            Customer Details
          </Typography>
          {/* {errors.phone ? toast.error(errors.phone.message) : null} */}
          <Form provider={methods} onSubmit={handleSubmit}>
            <Box height="1rem" />
            <Typography>First Name</Typography>
            <Input
              variant="outlined"
              name="first_name"
              placeholder="First Name"
              defaultValue={selectedAddress ? selectedAddress.first_name : ""}
            />
            <Typography>Last Name</Typography>
            <Input
              variant="outlined"
              name="last_name"
              placeholder="Last Name"
              defaultValue={selectedAddress ? selectedAddress.last_name : ""}
            />
            <Typography>Email</Typography>
            <Input
              variant="outlined"
              name="email"
              type="email"
              placeholder="email@email.com"
              defaultValue={selectedAddress ? selectedAddress.email : ""}
            />
            <Typography>Address</Typography>
            {orderProcessingType == 2 ? selectedAddress.address : ""}
            <Input
              hidden={orderProcessingType == 2 ? true : false}
              name="address"
              variant="outlined"
              placeholder="Address"
              defaultValue={selectedAddress ? selectedAddress.address : ""}
            />
            <Typography>City</Typography>
            {orderProcessingType == 2 ? selectedAddress.city : ""}
            <Input
              hidden={orderProcessingType == 2 ? true : false}
              name="city"
              variant="outlined"
              placeholder="City"
              defaultValue={selectedAddress ? selectedAddress.city : ""}
            />
            <Typography>Zip Code</Typography>
            {orderProcessingType == 2 ? selectedAddress.zipCode : ""}
            <Input
              onKeyDown={(e) => {
                if (parseInt(e.target.value) > 99999 && e.keyCode !== 8) {
                  e.preventDefault();
                }
                if (
                  (e.keyCode >= 48 && e.keyCode <= 57) ||
                  (e.keyCode >= 96 && e.keyCode <= 105) ||
                  e.keyCode == 8
                ) {
                } else {
                  e.preventDefault();
                }
              }}
              hidden={orderProcessingType == 2 ? true : false}
              name="zipCode"
              variant="outlined"
              placeholder="Zip Code"
              defaultValue={selectedAddress ? selectedAddress.zipCode : ""}
              autoComplete="off"
            />
            <Box height="6px" />
            <Typography gutterBottom>Mobile #</Typography>
            <Box height="6px" />
            <Controller
              as={
                <PhoneInput
                  defaultCountry="US"
                  inputClassName="customPhoneInput"
                  defaultValue={selectedAddress ? selectedAddress.phone : ""}
                />
              }
              name="phone"
            />
            <Box bottom="0px" left="0px" marginTop="2rem" width="100%">
              <Button
                type="submit"
                fullWidth
                fontSize="1.25rem"
                elevation={0}
                style={{
                  textTransform: "none",
                  padding: "9px 0rem",
                  borderRadius: "10px",
                  border: "1px solid #bebebe",
                  backgroundColor: "#cb4629",
                }}
                variant="contained"
              >
                Save Details
              </Button>
            </Box>
          </Form>
        </Container>
      </React.Fragment>
    </Drawer>
  );
};

export const TipsDrawer = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      <Box padding="1rem" height="70vh" position="relative">
        <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => {
            return (
              <Grid key={tip} item xs={3}>
                <Button>
                  <Box
                    display="flex"
                    //border="1px solid black"
                    //borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    padding="0rem"
                    width="60px"
                    height="60px"
                    onClick={() =>
                      setValue("tip", (tip * totalPriceItems).toFixed(2))
                    }
                  >
                    <Typography>{tip * 100} %</Typography>
                  </Box>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input label="Amount $" name="tip" />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button variant="contained" color="primary" fullWidth type="submit">
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

const PaymentSelector = ({ setOpenUserCards }) => {
  const { selectedAddress, orderProcessingDate } = useSelector(
    (state) => state.public
  );
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflowX: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));

  const classes = useStyles();

  const payments = [
    "Credit Cards",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      <Typography style={{ fontWeight: 700 }} gutterBottom>
        Types of Payment
      </Typography>

      <div className={classes.root} style={{ "overflow-x": "visible" }}>
        <ImageList
          style={{ width: "auto !important", "overflow-x": "visible" }}
          className={classes.gridList}
          cols={2.2}
        >
          {payments.map((payment) => {
            return (
              <ImageListItem
                key={payment}
                style={{
                  height: "auto",
                  margin: "0rem 6px",
                  padding: "1rem 0rem",
                  width: "auto !important",
                  paddingLeft: "0",
                }}
              >
                <Box
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#cb4629",
                    color: "#fff",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  padding="1rem"
                  border="1px solid #bebebe"
                  borderRadius="5px"
                  onClick={() => {
                    if (selectedAddress !== null && orderProcessingDate) {
                      if (!selectedAddress.first_name) {
                        alert("Please complete the customer details!");
                      } else {
                        setOpenUserCards(true);
                        dispatch({
                          type: "SET_SELECTED_MODE_PAYMENT",
                          payload: payment,
                        });
                      }
                    } else {
                      if (!orderProcessingDate) {
                        alert("Please provide Service Option details!");
                      } else {
                        alert("Please provide Customer details!");
                      }
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography>{payment}</Typography>
                    {payment === "Credit Cards" && (
                      <img
                        height="100%"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={CARDICONS}
                        style={{ maxWidth: "75px" }}
                      />
                    )}
                    {payment === "Gift Cards" && (
                      <img
                        height="100%"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={GIFTCARDS}
                        style={{ maxWidth: "30px" }}
                      />
                    )}
                    {payment === "Rewards" && (
                      <img
                        height="100%"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={COIN}
                        style={{ maxWidth: "25px" }}
                      />
                    )}
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
        <PaymentNested />
      </div>
    </>
  );
};

export const SelectCreditCardDrawer = ({
  open,
  setOpen,
  tax,
  deliveryCharge,
}) => {
  const [userCards, setUserCards] = useState([]);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (user) {
      const unsub = db
        .collection("users")
        .doc(user.id)
        .collection("cards")
        .onSnapshot((snapshot) => {
          setUserCards(
            snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            })
          );
        });

      return unsub;
    }
  }, [user]);
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box padding="1rem" height="80vh" position="relative">
        <Box height="40px" />
        <Typography style={{ fontWeight: 700 }}>{selectedPayment}</Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Box display="flex" alignItems="center">
                <input
                  type="radio"
                  checked={selectedPayment?.id === card.id}
                  style={{ marginRight: "1rem" }}
                />
                {card.CardType === "VISA" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    src={VISA}
                    alt="VISA"
                    style={{ width: "60px" }}
                  />
                ) : card.CardType === "AMEX" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    alt="AMEX"
                    src={AMEX}
                    style={{ width: "60px" }}
                  />
                ) : card.CardType === "M/C" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    src={MASTERCARD}
                    alt="mastercard"
                    style={{ width: "60px" }}
                  />
                ) : (
                  <Typography>{card.CardType}</Typography>
                )}
              </Box>
              <Typography>{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Rewards" && <RewardsAsPayment setOpen={setOpen} />}

        {selectedPayment == "Gift Cards" && (
          <GiftCardAsPayment setOpen={setOpen} />
        )}

        {selectedPayment == "Credit Cards" && (
          <Button
            fullWidth
            onClick={() => {
              history.push("/payment-wpay/", {
                tax: tax,
                deliveryCharge: deliveryCharge,
              });
            }}
          >
            use new card
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

// Select credit cart
// redirect to form
// call iframe
// collect response
// save db
