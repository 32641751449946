import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import React, { useEffect } from "react";

export const HomepageSlider = () => {
  useEffect(() => {
    document.title = "We are a full service bar and restaurant in Eureka Montana.";
  }, []);

  return (
    <div>
      <Carousel fade>
        <Carousel.Item>
          <img width="100%" height="100%" title="We are a full service bar and restaurant in Eureka Montana" className="d-block w-100" src={Slider1} alt="We are a full service bar and restaurant in Eureka Montana" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Where The West Is Still Wild</h1>
          <h5>We pride ourselves on tempting our guests with juicy hand-cut meat prepared the old-fashioned way on our smokers.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="We are a full service bar and restaurant in Eureka Montana" className="d-block w-100" src={Slider2} alt="We are a full service bar and restaurant in Eureka Montana" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Our outdoor stage lights up the night</h1>
          <h5>Top artists from all over North America playing to a crowd dancing on the grass dance floor.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="We are a full service bar and restaurant in Eureka Montana" className="d-block w-100" src={Slider3} alt="We are a full service bar and restaurant in Eureka Montana" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Our location will take your breath away</h1>
          <h5>Our outdoor patios are blooming with hanging flower baskets, refreshing cocktails, ice cold beer, and the sound of horseshoes clanking as friends compete in a friendly round.
</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="We are a full service bar and restaurant in Eureka Montana" className="d-block w-100" src={Slider4} alt="We are a full service bar and restaurant in Eureka Montana" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Where The West Is Still Wild</h1>
          <h5>We pride ourselves on tempting our guests with juicy hand-cut meat prepared the old-fashioned way on our smokers.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="We are a full service bar and restaurant in Eureka Montana" className="d-block w-100" src={Slider5} alt="We are a full service bar and restaurant in Eureka Montana" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Our outdoor stage lights up the night</h1>
          <h5>Top artists from all over North America playing to a crowd dancing on the grass dance floor.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};